import React, { useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  userActions,
  getUserMappings,
  getCurrentUserOrg,
  getOrgActiveUsers,
  getOrgInActiveUsers,
  getOrgDetailsById,
  isAdmin,
  isArchitect,
  isOrganizer,
  getCurrentUser,
  getUserActivatedOrDeactivatedStatus,
  isAccessor,
  getRolesIdsList,
  getRoleById,
  continuerResponderList,
  investigatorResponderList,
  orgActions,
  arcResponderList,
} from "../../store";
import {
  Table,
  Input,
  SearchIcon,
  PrimaryButton,
  PlusIcon,
  ConfirmationBox,
} from "../../components";
import { UserCreateLimitPopup } from "../Form";
import "./style.scss";

const Columns = [
  {
    label: "Name",
    width: "20%",
    align: "left",
    attr: "name",
    isClickable: true,
    className: "link",
  },
  { label: "Email", width: "30%", align: "left", attr: "email" },
  { label: "Department", width: "18%", align: "left", attr: "department_name" },
  { label: "Role", width: "17%", attr: "roleName" },
  {
    label: "Action(s)",
    width: "15%",
    type: "actions",
    dataCellClassName: "row h-ctr actions",
  },
];
const ActiveAction = [{ label: "Deactivate", action: "deactivate" }];
const InactiveAction = [{ label: "Activate", action: "activate" }];

// const MappingRequestColumns = [
//   { label: "Org Name", width: '12%', align: 'left', attr: 'brandname' },
//   { label: "Requester", width: '18%', align: 'left', attr: 'requester' },
//   { label: "Requester Email", width: '29%', align: 'left', attr: 'requesterEmail' },
//   { label: "Requested", width: '17%', attr: 'requested', align: 'left' },
//   { label: "Date", width: '12%', attr: 'datetime', align: 'left' },
//   { label: "Action(s)", width: '10%', type: 'actions', dataCellClassName: "row h-arnd" },
// ]

const UserMainScreen = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { orgId } = useParams();
  const bulkUploadRef = React.createRef(null);
  const roles = useSelector(getRolesIdsList);
  const org = useSelector(
    orgId ? getOrgDetailsById.bind(null, orgId) : getCurrentUserOrg
  );
  const user = useSelector(getCurrentUser);
  const status = useSelector(getUserActivatedOrDeactivatedStatus);
  const [state, setState] = useState({
    search: "",
    hideModal: false,
    confirmMsg: null,
    show: false,
    showLimitWarning: false,
  });
  const activeUsers = useSelector(
    getOrgActiveUsers.bind(null, org.id, state.search)
  );
  const inActiveUsers = useSelector(
    getOrgInActiveUsers.bind(null, org.id, state.search)
  );
  const userMappings = useSelector(getUserMappings);
  const continuer_responder_list = useSelector(continuerResponderList);
  const investigator_responder_list = useSelector(investigatorResponderList);
  const arc_responder_list = useSelector(arcResponderList);
  const canAction =
    isAdmin(user) || isOrganizer(user) || isArchitect(user) || isAccessor(user);
  const fetchOrgUsers = () => {
    dispatch(userActions.fetchActiveUsers({ orgId: org.id }));
    dispatch(userActions.fetchInActiveUsers({ orgId: org.id }));
  };
  useEffect(() => {
    dispatch(orgActions.fetchContinuerRespondersList());
    dispatch(orgActions.fetchInvestigatorRespondersList());
    dispatch(orgActions.fetchArcRespondersList());
  }, []);
  const getOrgUser = () => {
    let res = [],
      callback = (_) => {
        let user = { ..._ };
        if (canAction) {
          user.actions = (
            user.is_disabled === true ? InactiveAction : ActiveAction
          ).map((action) => {
            return { ...action };
          });
        }
        let role = getRoleById(roles, user.role_id);
        user.roleName = role ? role.label : "-";
        return user;
      };
    if (Array.isArray(activeUsers)) {
      res = activeUsers.map(callback);
    }
    if (Array.isArray(inActiveUsers)) {
      res = res.concat(inActiveUsers.map(callback));
    }
    return res;
  };
  useEffect(() => {
    dispatch(userActions.fetchUserMappingsSuccess(null));
    fetchOrgUsers();
    dispatch(userActions.fetchUserMappings({ orgId: Number(orgId) }));
    setState((_) => ({ ..._, show: true }));
  }, []);
  useEffect(() => {
    if (status) {
      dispatch(userActions.toggleActivateSuccess(false));
      fetchOrgUsers();
    }
  }, [status]);

  const handleDeactiveUser = (action, row) => {
    if (action.action === InactiveAction[0].action) {
      if (org.user_limit !== -1 && activeUsers.length >= org.user_limit) {
        //show user limit dialog
        return setState((_) => ({ ..._, showLimitWarning: "activation" }));
      }
    }
    const isUserContinuerResponder =
      continuer_responder_list?.includes(row.id) || false;
    const isUserInvestigatorResponder =
      investigator_responder_list?.includes(row.id) || false;
    const isUserArcResponder = arc_responder_list?.includes(row.id) || false;
    if (
      isUserContinuerResponder ||
      isUserInvestigatorResponder ||
      isUserArcResponder
    ) {
      let userRole = "";
      if (isUserContinuerResponder) {
        userRole = "Continuer";
      }
      if (isUserInvestigatorResponder) {
        userRole += (userRole ? " & " : "") + "Investigator";
      }
      if (isUserArcResponder) {
        userRole += (userRole ? " & " : "") + "Arc";
      }
      const confirmMsg = {
        title: "",
        action: handleCancel,
        actionRow: false,
        row: row,
      };
      confirmMsg.titleClass = "cFF3333";
      confirmMsg.title = "Action not permitted!";
      confirmMsg.btnOK = "Okay";
      confirmMsg.btnOKColor = "#FF3333";
      confirmMsg.subtitle = [
        `The user is defined as responder in ${userRole}. Please change the responder in ${userRole}.`,
      ];
      setState((_) => ({ ..._, confirmMsg: confirmMsg }));
    } else {
      const btnCancel = {
        onClick: handleCancel,
        text: "Go back",
        type: "link",
      };
      const confirmMsg = {
        title: "",
        action: action.action,
        actionRow: false,
        row: row,
        btnCancel: btnCancel,
      };
      if (action.action === "activate") {
        confirmMsg.titleClass = "c00CC55";
        confirmMsg.title = "Activate User";
        confirmMsg.btnOK = "Activate";
        confirmMsg.btnOKColor = "#00CC55";
        confirmMsg.subtitle = [
          `Activating the User will allow them to take all permitted actions in fusion`,
        ];
      } else if (action.action === "deactivate") {
        confirmMsg.titleClass = "cFF3333";
        confirmMsg.title = "Deactivate User";
        confirmMsg.btnOK = "Deactivate";
        confirmMsg.btnOKColor = "#FF3333";
        confirmMsg.withPassword = true;
        confirmMsg.subtitle = [
          `Deactivating the Account will prevent the User from logging in to fusion`,
        ];
      }
      setState((_) => ({ ..._, confirmMsg: confirmMsg }));
    }
  };

  const handleCellClick = (row, col) => {
    dispatch(userActions.fetchUserSuccess(null));
    if (orgId) {
      navigate(String(row.id));
    } else {
      navigate("/U/" + row.id + "/view");
    }
  };
  const handleOk = (e, password) => {
    const { action, row } = state.confirmMsg;
    // eslint-disable-next-line default-case
    switch (action) {
      case "accept":
      case "reject":
        const body = {
          customer_id: row.customerId,
          user_id: row.userId,
          vendor_id: row.vendorId,
          activated: action === "accept",
        };
        dispatch(userActions.acceptOrRejectUserMapping(body));
        break;
      case "activate":
      case "deactivate":
        dispatch(userActions.toggleActivate({ userId: row.id, password }));
        break;
    }
    handleCancel();
  };
  const handleNew = () => {
    if (org.user_limit !== -1 && activeUsers.length >= org.user_limit) {
      //show user limit dialog
      return setState((_) => ({ ..._, showLimitWarning: "creation" }));
    }
    if (orgId) {
      navigate(`new`);
    } else {
      navigate("/U/new");
    }
  };
  const handleBulkUpload = () => {
    if (org.user_limit !== -1 && activeUsers.length >= org.user_limit) {
      //show user limit dialog
      return setState((_) => ({ ..._, showLimitWarning: true }));
    }
    navigate(`upload`);
  };
  const handleCancel = () => {
    setState((_) => ({ ..._, confirmMsg: null }));
  };
  return (
    <div className="col w-100 h-100 user-map-scr tog-tbl">
      <h3 className="h5 exo2 med title">User</h3>
      <div className="row h-btn v-start">
        <Input
          className="h9 search"
          placeholder="Search"
          value={state.search}
          icon={<SearchIcon className="search-icon" />}
          onChange={(e) => setState((_) => ({ ..._, search: e.target.value }))}
        />
        <div className="row">
          <PrimaryButton
            text="Create New User"
            endIcon={<PlusIcon className="h10" />}
            onClick={handleNew}
          />
          {Boolean(orgId) && (
            <PrimaryButton
              text="Upload Users"
              className="ml-1"
              onClick={handleBulkUpload}
            />
          )}
          {/*
            canAction &&
            <PrimaryButton text="Invite External User" className="btn-invite-user" endIcon={<PlusIcon className='h10' />} onClick={handleInviteUser} />
  */}
        </div>
      </div>
      <div className="col f-rest o-hide">
        <div className="col w-100 h-100 o-hide">
          <div className="col f-rest oy-auto tbl-container hsc">
            {Array.isArray(activeUsers) || Array.isArray(inActiveUsers) ? (
              <Table
                columns={Columns}
                className="w-100 h-100 tbl-org"
                rows={state.show ? getOrgUser() : null}
                emptyMessage="No Users"
                onAction={handleDeactiveUser}
                onCell={handleCellClick}
              />
            ) : (
              <div className="col w-100 h-100 v-ctr h-ctr">
                <CircularProgress />
              </div>
            )}
          </div>
          {/* removed the mapped user section */}
          {/* <div className='col f-rest o-hide tbl-container '>
            <Button
              disableElevation
              text='Mapped User(s)'
              color='transparent'
              className='exo2 h5 btn-map'
              onClick={() => {
                setState((_) => ({ ..._, hideModal: !_.hideModal }))
              }}
              startIcon={<ArrowDropUpIcon className={`h15 arrow rt ${state.hideModal ? '' : 'show'}`} />}
            />
            <div className={`col f-rest collapse ${state.hideModal ? '' : 'show'}`}>
              {
                Boolean(userMappings) ?
                  <div className={`col w-100 h-100 oy-auto hsc`}>
                    <Table
                      emptyMessage='No Mapped User'
                      columns={MappingRequestColumns}
                      rows={userMappings}
                      className='w-100 h-100' onAction={handleAcceptReject}
                    />
                  </div>
                  :
                  <OvalLoading isSmall />
              }
            </div>
          </div> */}
        </div>
      </div>
      {Boolean(state.confirmMsg) && (
        <ConfirmationBox
          closeOnEnter
          row={state.confirmMsg.actionRow}
          className=""
          titleClassName={state.confirmMsg.titleClass}
          btnCancel={state.confirmMsg.btnCancel}
          btnOK={{
            text: state.confirmMsg.btnOK,
            color: state.confirmMsg.btnOKColor,
            onClick: handleOk,
          }}
          withPassword={state.confirmMsg.withPassword}
          title={state.confirmMsg.title}
          subtitle={state.confirmMsg.subtitle}
        />
      )}
      {Boolean(state.showLimitWarning) && (
        <UserCreateLimitPopup
          org={org}
          actionType={state.showLimitWarning}
          onClose={() => {
            setState((_) => ({ ..._, showLimitWarning: false }));
          }}
        />
      )}
    </div>
  );
};
// const handleAcceptReject = (action, row) => {
//   const confirmMsg = { title: "External User Mapping", action: action.action, row: row }
//   if (action.action === 'accept') {
//     confirmMsg.btnOK = 'Accept';
//     confirmMsg.btnOKColor = "#00CC55"
//     confirmMsg.subtitle = [`Are you sure to accept the user mapping request of ${row.requester} from ${row.brandname} for ${row.requested}?`];
//   } else if (action.action === 'reject') {
//     confirmMsg.btnOK = 'Reject';
//     confirmMsg.btnOKColor = "#FF3333"
//     confirmMsg.subtitle = [`Are you sure to reject the user mapping request of ${row.requester} from ${row.brandname} for ${row.requested}?`];
//   }
//   setState((_) => ({ ..._, confirmMsg: confirmMsg }))
// }
// const handleInviteUser = () => {
//   dispatch(userActions.inviteExternalUserSuccess(null))
//   dispatch(userActions.inviteUserSuccess(null))
//   if (orgId) {
//     navigate(`invite`)
//   } else {
//     navigate('/U/invite')
//   }
// }

export default UserMainScreen;
