import React, { useEffect, useState, useRef } from "react";
import MuiCheckbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import AdapterDateFns from "@mui/lab/AdapterMoment";
import { LocalizationProvider, DesktopDatePicker } from "@mui/lab";
import { PrimaryButton } from "../Button";
import { FileIcon } from "../Icon";
import moment from "moment";
import "./style.scss";

export const InputContainer = (props) => {
  const {
    id,
    hint,
    label,
    maxlength,
    error,
    showError,
    value,
    className,
    labelClassName,
    required,
    disabled,
  } = props;
  return (
    <div className={`col input-group ${className || ""}`}>
      {Boolean(label) && (
        <label
          htmlFor={id}
          className={`label ${required ? "req" : ""} ${
            labelClassName || "h12"
          }`}
        >
          {label}
        </label>
      )}
      {Boolean(hint) && <div className="hint">{hint}</div>}
      <div
        className={`row input-container ${Boolean(error) ? "error" : ""} ${
          Boolean(disabled) ? "disabled" : ""
        }`}
      >
        {props.children}
      </div>
      {Boolean(showError) && Boolean(error) && (
        <span className="error-text f12 cBB2222">{error}</span>
      )}
      {Boolean(maxlength) &&
        !isNaN(Number(maxlength)) &&
        Number(maxlength) > 0 && (
          <div className={`row full`}>
            <div className="h6 max-len">
              {String(value || "").length} / {maxlength} Characters
            </div>
          </div>
        )}
    </div>
  );
};
const getDateFormatValue = (value) => {
  return value ? moment(value).format("ddd, DD-MMM-yyyy") : "ddd, DD-MMM-yyyy";
};

export const Input = (props) => {
  const {
    id,
    hint,
    label,
    showError,
    placeholder,
    maxlength,
    className,
    error,
    optional,
    icon,
    labelClassName,
    disabled,
    editable,
    required,
    ...rest
  } = props;
  let _editable = editable === undefined ? true : editable;
  return (
    <InputContainer
      showError={showError}
      required={required}
      disabled={disabled}
      labelClassName={labelClassName}
      error={error}
      hint={hint}
      className={`${!_editable ? "input-noedit" : ""} ${className || ""}`}
      id={id}
      label={label}
      maxlength={maxlength}
      value={rest.value}
    >
      <input
        id={id || ""}
        maxLength={maxlength}
        placeholder={_editable ? placeholder || label : ""}
        className="input"
        disabled={disabled || !_editable}
        {...rest}
      />
      {Boolean(optional) && <div className="h11 hint-optinal">Optional</div>}
      {Boolean(icon) && icon}
    </InputContainer>
  );
};
export const Textarea = (props) => {
  const {
    id,
    hint,
    placeholder,
    label,
    maxlength,
    className,
    error,
    optional,
    labelClassName,
    ...rest
  } = props;
  return (
    <InputContainer
      hint={hint}
      className={`textarea-container ${className}`}
      id={id}
      error={error}
      labelClassName={labelClassName}
      label={label}
      maxlength={maxlength}
      value={rest.value}
    >
      <textarea
        id={id || ""}
        maxLength={maxlength}
        placeholder={label || placeholder}
        {...rest}
      />
      {Boolean(optional) && <div className="h11 hint-optinal">(Optional)</div>}
    </InputContainer>
  );
};
export const Checkbox = (props) => {
  const {
    id,
    hint,
    placeholder,
    label,
    maxlength,
    className,
    error,
    optional,
    labelClassName,
    value,
    editable,
    ...rest
  } = props;
  let _editable = editable === undefined ? true : editable;
  return (
    <InputContainer
      className={`checkbox ${!_editable ? "input-noedit" : ""}`}
      label={_editable ? "" : label}
    >
      {_editable ? (
        <FormControlLabel
          control={
            <MuiCheckbox name={rest.name} checked={value || false} {...rest} />
          }
          label={label}
          classes={{ label: `label ${labelClassName || "h7"}` }}
        />
      ) : (
        <div className="h7">{value ? "Yes" : "No"}</div>
      )}
    </InputContainer>
  );
};
export const DateSelect = (props) => {
  const { id, label, className, placeholder, value, ...rest } = props;
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DesktopDatePicker
        label="Custom input"
        className="h5"
        value={value}
        disabled={rest.disabled}
        onChange={(_moment) => {
          rest.onChange &&
            rest.onChange({
              target: { name: rest.name, value: _moment.toDate() },
            });
        }}
        renderInput={({ inputRef, inputProps, InputProps }) => {
          return (
            <InputContainer
              className={`date ${className}`}
              label={label}
              placeholder={placeholder}
              error={rest.error}
            >
              <input
                id={`${id || ""}`}
                type="text"
                ref={inputRef}
                {...inputProps}
                value={getDateFormatValue(inputProps.value)}
                placeholder="Ddd, dd-Mmm-yyyy"
              />
              {InputProps?.endAdornment}
            </InputContainer>
          );
        }}
      />
    </LocalizationProvider>
  );
};
export const DatePicker = (props) => {
  const { id, label, className, placeholder, value, ...rest } = props;
  const [open, setOpen] = useState(false);
  const onOpenDate = (e) => {
    e.stopPropagation();
    if (!rest.disabled) {
      setOpen(true);
    }
  };
  const onDocumentClick = () => {
    setOpen(false);
  };
  useEffect(() => {
    document.addEventListener("click", onDocumentClick);
    return () => {
      document.removeEventListener("click", onDocumentClick);
    };
  }, []);
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DesktopDatePicker
        label="Custom input"
        open={open}
        value={value}
        disabled={rest.disabled}
        onChange={(_moment) => {
          console.log(_moment);
          rest.onChange &&
            rest.onChange({
              target: { name: rest.name, value: _moment.toDate() },
            });
        }}
        renderInput={({ inputRef, inputProps, InputProps }) => {
          return (
            <InputContainer className={className} error={rest.error}>
              {Boolean(label) && <label>{label}</label>}
              <button
                type="button"
                className="date-btn row"
                onClick={onOpenDate}
              >
                <span ref={inputRef}>{getDateFormatValue(value)}</span>
                {InputProps?.endAdornment}
              </button>
            </InputContainer>
          );
        }}
      />
    </LocalizationProvider>
  );
};

export const FileInput = (props) => {
  const {
    id,
    hint,
    placeholder,
    label,
    className,
    error,
    labelClassName,
    value,
    preview,
    required,
    ...rest
  } = props;
  const _inputRef = useRef(null);
  return (
    <InputContainer
      hint={hint}
      className={`file-input-group ${className}`}
      id={id}
      error={error}
      label={label}
      labelClassName={labelClassName}
      required={required}
    >
      <div className="row v-center f-rest o-hide">
        <input hidden {...rest} ref={_inputRef} type="file" />
        {Boolean(preview) && Boolean(value) && (
          <img
            src={
              value.isNotFile
                ? `https:${value.url}`
                : URL.createObjectURL(value)
            }
            className="preview"
            alt={rest.name}
          />
        )}
        <span className="file-label o-hide w-100">
          {value ? value.name : label || placeholder}
        </span>
      </div>
      <PrimaryButton
        disableElevation
        text="Choose File"
        className="h11"
        endIcon={<FileIcon />}
        onClick={() => {
          if (_inputRef.current) {
            _inputRef.current.click();
          }
        }}
      />
    </InputContainer>
  );
};
export * from "./dropdown";
