import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Grid } from "@mui/material";
import { Screen, HelpSection, useNavigateBack } from "../../components";
import { useDispatch, useSelector } from "react-redux";
import {
  isLoading,
  getCurrentUserOrg,
  getCurrentUser,
  userActions,
  isUserCreated,
  getOrgDetailsById,
  getOrgActiveUsers,
} from "../../store";
import { UserForm, UserCreateLimitPopup } from "../Form";
import "./style.scss";

const CreateUser = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const navigateBack = useNavigateBack();
  const loading = useSelector(isLoading);
  const { orgId } = useParams();
  const currentOrg = useSelector(getCurrentUserOrg);
  const org = useSelector(
    orgId ? getOrgDetailsById.bind(null, orgId) : getCurrentUserOrg
  );
  const isCreated = useSelector(isUserCreated);
  const currentUser = useSelector(getCurrentUser);
  const [showUserLimitError, setShowUserLimitError] = useState(false);
  const users = useSelector(getOrgActiveUsers.bind(null, orgId, ""));
  useEffect(() => {
    if (!org) {
      return navigate("/page-not-found");
    }
    if (org) {
      dispatch(userActions.fetchActiveUsers({ orgId: orgId }));
    }
  }, [org]);
  useEffect(() => {
    if (isCreated) {
      handleCancel();
    }
  }, [isCreated]);
  const handleCancel = () => {
    setShowUserLimitError(false);
    dispatch(userActions.createUserSuccess(false));
    navigateBack();
  };
  const onSubmit = (formData) => {
    formData.ent_org_id = org.id;
    if (org.userLimit !== -1 && users.length >= org.userLimit) {
      return setShowUserLimitError(true);
    }
    const body = { ent_usr: formData };
    dispatch(userActions.createUser(body));
  };
  return (
    <div className="col w-100 h-100 o-hide">
      <Screen className="user-add col f-rest o-hide">
        <Grid container spacing={0} classes={{ root: "o-hide" }}>
          <Grid
            item
            xs={12}
            sm={12}
            md={9}
            lg={8}
            classes={{ root: "col h-100  o-hide" }}
            direction="column"
          >
            <h1 className="exo2 h5 med page-head">
              Create New User{" "}
              {org.id !== currentOrg.id ? `for ${org.brandname}` : ""}
            </h1>
            <UserForm
              currentUser={currentUser}
              onSubmit={onSubmit}
              disabled={loading}
              onCancel={handleCancel}
            />
          </Grid>
          <HelpSection xs={12} sm={12} md={3} lg={4} />
        </Grid>
      </Screen>
      {showUserLimitError && (
        <UserCreateLimitPopup org={org} onClose={handleCancel} />
      )}
    </div>
  );
};
export default CreateUser;
