import React, { useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
import { useDispatch, useSelector, connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Table,
  Input,
  PrimaryButton,
  SearchIcon,
  PlusIcon,
  Button,
  ArrowDropUpIcon,
} from "../../components";
import {
  orgActions,
  userActions,
  getAllOrgs,
  isAdmin,
  getCurrentUser,
} from "../../store";

import "./style.scss";

const Columns = [
  {
    label: "Brand Name",
    width: "15%",
    align: "left",
    attr: "brandname",
    className: "link",
    isClickable: true,
  },
  { label: "Allocated Models", width: "35%", attr: "models_count" },
  {
    label: "Assessments",
    width: "25%",
    attr: "assessmens",
    children: [
      { label: "Pending", attr: "pending" },
      { label: "Completed", attr: "completed" },
      { label: "Total", attr: "total" },
    ],
  },
  { label: "Assessors", width: "15%", attr: "auditors_count" },
  { label: "Auditees", width: "10%", attr: "auditees_count" },
];

const Settings = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const orgs = useSelector(getAllOrgs);
  const currentUser = useSelector(getCurrentUser);
  const [state, setState] = useState({ search: "", hideModal: false });
  useEffect(() => {
    dispatch(orgActions.fetchOrgs());
  }, []);
  const handleOrgCellClick = (row, col) => {
    dispatch(orgActions.getAcceptedOrgsSuccess(null));
    dispatch(orgActions.fetchPendingOrgsSuccess(null));
    dispatch(userActions.fetchUserMappingsSuccess(null));
    // console.log(`PAth : /O/${row.id}`);
    navigate(`/O/${row.id}`, { state: { from: "T" } });
  };
  const handleCreateOrg = (row, col) => {
    navigate("/O/create");
  };
  return (
    <div className="col w-100 h-100 settings">
      <div className="row h-btn v-end tble-cont">
        <h3 className="h5 exo2 med title">Organisation(s)</h3>
        {isAdmin(currentUser) && (
          <PrimaryButton
            text="Create New Organisation"
            className="btn-create"
            endIcon={<PlusIcon className="h10" />}
            onClick={handleCreateOrg}
          />
        )}
      </div>
      <div className="col f-rest o-hide">
        {orgs === null ? (
          <div className="col v-ctr h-ctr w-100 h-100">
            <CircularProgress />
          </div>
        ) : (
          <div className="col w-100 h-100 o-hide">
            <div className="col w-100 h-50 o-hide">
              <Table
                emptyMessage="No Active Organisations"
                columns={Columns}
                className="tbl-org col f-rest"
                rows={orgs.active_orgs}
                onCell={handleOrgCellClick}
              />
            </div>
            <div className="col w-100 h-50 o-hide tble-cont">
              <div className="exo2 h5 title dis">Disabled Organisation(s)</div>
              <Table
                emptyMessage="No Disabled Organisation(s)"
                className="tbl-org col f-rest"
                columns={Columns}
                rows={orgs.disable_orgs}
                onCell={handleOrgCellClick}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default Settings;
