import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { AutoCompleteSelect, PrimaryButton, Input } from "../../components";
import {
  orgActions,
  userActions,
  getCurrentUserOrg,
  getNotificationFreqs,
  getOrgDetailsById,
  getOrgActiveUsers,
} from "../../store";
import { UserLimitUpdatePopup } from "../Form";
import { Switch } from "@mui/material";
import "./style.scss";
const UserLimitRegex = /^[1-9]\d*$/;
const NoLimit = "no limit";

const getOrgConfig = (org) => {
  const user_limit = org.user_limit > 0 ? org.user_limit : "No Limit";
  return {
    notificationType: org.notification_frequency,
    multiAuth: org.otp_required || false,
    userLimit: user_limit,
  };
};
const Configuration = (props) => {
  const { isInternal } = props;
  const dispatch = useDispatch();
  const { orgId } = useParams();
  const currentOrg = useSelector(getCurrentUserOrg);
  const org = useSelector(getOrgDetailsById.bind(null, orgId));
  const frequencies = useSelector(getNotificationFreqs);
  const users = useSelector(getOrgActiveUsers.bind(null, org.id, ""));
  const [state, setState] = useState({
    isEdited: false,
    config: getOrgConfig(orgId ? org : currentOrg),
    showLimitUpdateWarning: false,
  });
  useEffect(() => {
    dispatch(orgActions.fetchNotifyFreqList());
    dispatch(userActions.fetchActiveUsers({ orgId: orgId }));
  }, []);
  const handleSettingsChange = (e) => {
    const config = { ...state.config };
    let { name, value, checked } = e.target;
    value = name === "multiAuth" ? checked : value;
    if (name === "userLimit") {
      value = value && (isLimitNumber(value) || isNoLimit(value)) ? value : "";
    }
    config[name] = value;
    setState((_) => ({ ..._, config }));
  };
  const handleBlurField = (e) => {
    const { name } = e.target;
    const config = { ...state.config };
    const value = config[name];
    if (!value || (!isLimitNumber(value) && !isNoLimit(value))) {
      config[name] = org.user_limit;
      setState((_) => ({ ..._, config }));
    }
  };
  const isLimitNumber = (value) => {
    return UserLimitRegex.test(value);
  };
  const isNoLimit = (value) => {
    return NoLimit.indexOf(String(value).toLowerCase()) === 0;
  };
  const handleUpdate = () => {
    let userLimit = state.config.userLimit;
    userLimit = isLimitNumber(userLimit)
      ? userLimit
      : isNoLimit(userLimit)
      ? -1
      : org.user_limit;
    userLimit = Number(userLimit);
    if (userLimit !== -1 && users.length > userLimit) {
      return setState((_) => ({ ..._, showLimitUpdateWarning: true }));
    }
    const config = {
      multiAuth: state.config.multiAuth,
      orgId: orgId || currentOrg.id,
    };
    if (state.config.notificationType && state.config.notificationType.id) {
      config.notify = state.config.notificationType.id;
    }
    if (Number(userLimit) !== Number(org.user_limit)) {
      config.user_limit = userLimit;
    }
    dispatch(orgActions.setOrgConfig(config));
  };
  const handleWarningClose = () => {
    const config = getOrgConfig(orgId ? org : currentOrg);
    setState((_) => ({
      ..._,
      showLimitUpdateWarning: false,
      config: { ..._.config, userLimit: config.userLimit },
    }));
  };
  return (
    <div
      className={`col  w-100 h-100 o-hide config ${
        isInternal ? "internal" : ""
      }`}
    >
      <form className="settings-form oy-auto">
        <div className="h6 label bold exo2 head">Notifications</div>
        <div className="col sec">
          <div className="h7 label med exo2 ">
            Comments Notification Frequency
          </div>
          <div className="row form-input">
            <span className="h8 label med">Notify</span>
            <AutoCompleteSelect
              value={state.config.notificationType}
              options={frequencies}
              name="notificationType"
              className="h8"
              containerClass="select-group "
              onChange={handleSettingsChange}
            />
          </div>
        </div>
        <div className="row head">
          <div className="h6 label bold exo2">Multi Factor Authentication</div>
          <div className="col">
            <div className="row form-input" style={{ marginTop: 0 }}>
              <span className="h8 label med">Enable</span>
              <Switch
                name="multiAuth"
                checked={state.config.multiAuth}
                onChange={handleSettingsChange}
              />
            </div>
          </div>
        </div>
        <div className="row head user-limit-sec">
          <div className="row user-limit-field">
            <div className="h6 label bold exo2">User Limit</div>
            <Input
              name="userLimit"
              value={state.config.userLimit}
              className="h8"
              onChange={handleSettingsChange}
              onBlur={handleBlurField}
            />
          </div>
          <div className="row user-limit-field">
            <div className="h6 label bold active-label exo2">Active Users</div>
            <Input
              disabled={true}
              value={users && users.length}
              className="h8"
            />
          </div>
        </div>

        <PrimaryButton
          text="Update"
          color="#1177CC"
          className="h4 update"
          onClick={handleUpdate}
          endIcon={<i className={`h4 icon-arrow-round-forward`} />}
        />
      </form>
      {state.showLimitUpdateWarning && (
        <UserLimitUpdatePopup onClose={handleWarningClose} />
      )}
    </div>
  );
};

export default Configuration;
