import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Grid } from "@mui/material";
import { useNavigateBack, ConfirmationBox } from "../../../components";
import {
  orgActions,
  getOrgDetailsById,
  isOrgUpdateSuccess,
} from "../../../store";
import { OrgForm } from "../Components/Form";
import { useSelector, useDispatch } from "react-redux";
import "../style.scss";

const OrgEdit = (props) => {
  const { orgId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const navigateBack = useNavigateBack();
  const org = useSelector(getOrgDetailsById.bind(null, orgId));
  const isUpdated = useSelector(isOrgUpdateSuccess);
  const [state, setState] = useState({ showDisable: false });
  useEffect(() => {
    console.log("org", org);

    if (!org) {
      dispatch(orgActions.fetchOrgById({ orgId: orgId }));
    }
  }, []);
  useEffect(() => {
    if (isUpdated) {
      handleCancel();
    }
  }, [isUpdated]);
  const onUpdate = (formData) => {
    formData.append("ent_org_id", orgId);
    dispatch(orgActions.creatOrUpdateOrg({ org: formData }));
  };
  const handleCancel = () => {
    dispatch(
      orgActions.createOrUpdateOrgStatus({ create: false, updated: false })
    );
    navigateBack();
  };
  const handleDeactivate = (e, password) => {
    dispatch(
      orgActions.enableOrDisableOrg({
        password,
        orgId: org.id,
        enabling: org.is_disabled,
      })
    );
    navigate("/T");
  };
  const handleGoBack = (val) => {
    setState((_) => ({ ..._, showDisable: val }));
  };
  return (
    <Grid container classes={{ root: "o-hide h-100" }}>
      <Grid
        item
        xs={12}
        sm={12}
        md={8}
        lg={8}
        classes={{ root: "oy-auto h-100" }}
      >
        {Boolean(org) && (
          <OrgForm
            edit
            deactivate
            org={org}
            btnSubmit="Save Changes"
            btnReset="Cancel"
            onSubmit={onUpdate}
            onReset={handleCancel}
            onDeactivate={() => handleGoBack(true)}
          />
        )}
        {state.showDisable && (
          <ConfirmationBox
            closeOnEnter
            row={false}
            className=""
            titleClassName="cFF3333"
            btnCancel={{
              onClick: () => handleGoBack(false),
              text: "Go back",
              type: "link",
            }}
            btnOK={{
              text: "Deactivate",
              color: "#FF3333",
              onClick: handleDeactivate,
            }}
            withPassword
            title="Deactivate Organisation"
            subtitle="Deactivating the organisation will prevent the User from logging in to Fusion"
          />
        )}
      </Grid>
    </Grid>
  );
};
export default OrgEdit;
